<template>
<div id="">
  <v-flex xs12 class="headline pa-2">
    General information
  </v-flex>

  <v-text-field v-model="tUser.userName" :label="$t('profile.username')">
  </v-text-field>
  <v-text-field v-model="tUser.name" :label="$t('profile.name')" :hint="$t('profile.nameHint')"></v-text-field>
  <v-text-field v-model="tUser.nickname" label="Nickname" hint="Add a nickname only visible for Admins" v-if="adminEdit==true">{{tUser.nickname}}</v-text-field>
  <v-menu ref="birthdayMenu" :close-on-content-click="false" v-model="birthdayMenu" offset-y full-width :nudge-right="40" min-width="290px">
    <v-text-field slot="activator" :label="$t('profile.birthday')" v-model="tUser.birthday" prepend-icon="event" readonly></v-text-field>
    <v-date-picker :locale="$vuetify.lang.current" ref="picker" v-model="tUser.birthday" @change="$refs.birthdayMenu.save()" min="1950-01-01" :max="maxBday"></v-date-picker>
  </v-menu>
  <v-select :items="genders" v-model="tUser.gender" item-text="text" item-value="val" :label="$t('profile.gender')"></v-select>
  <NationalityDropdown :nationality="tUser.nationality" @changeNationality="tUser.nationality=$event"></NationalityDropdown>
  <v-text-field v-model="tUser.address" :label="$t('profile.address')"></v-text-field>
  <v-text-field v-model="tUser.passport" :label="$t('profile.passport')"></v-text-field>
  <v-checkbox :label="$t('profile.sendChinese')" v-model="tUser.sendChinese"></v-checkbox>
  <v-select
    :items="policies"
    v-model="tUser.cancelPol"
    :label="$t('profile.cancelPolicy')"
    :hint="$t('profile.cancelPolHint')"
    id="id"
  ></v-select>
  <v-btn color="primary" flat :href="'https://myroomabroad.com/policies/terms#cancellation'">{{$t('profile.policies')}}</v-btn>
  <v-flex xs12 class="headline pa-2">
    {{$t('profile.communicationInfo')}}
  </v-flex>
  <v-select :items="communicationOptions" v-model="tUser.communication" :label="$t('profile.preferredMethodHint')"></v-select>
  <v-text-field v-model="tUser.phone" :label="$t('profile.phone')"></v-text-field>
  <v-text-field v-model="tUser.lineId" :label="$t('profile.line')"></v-text-field>
  <v-text-field v-model="tUser.weChat" :label="$t('profile.wechat')"></v-text-field>
  <v-text-field v-model="tUser.facebook" :label="$t('profile.facebook')"></v-text-field>
  <v-flex xs12 class="headline pa-2">
    {{$t('profile.bankInfo')}}
  </v-flex>
  <v-switch v-model="tUser.companyInv" :label="$t('profile.companyInv')"></v-switch>
  <v-text-field v-model="tUser.companyId" :label="$t('profile.companyId')"></v-text-field>
  <v-text-field v-model="tUser.companyName" :label="$t('profile.companyName')"></v-text-field>
  <v-text-field v-model="bankInfo.holderName" :label="$t('paymentPage.accountHolder')+'*'"></v-text-field>
  <v-text-field v-model="bankInfo.accountName" :label="$t('paymentPage.accountName')+'*'"></v-text-field>
  <v-text-field v-model="bankInfo.accountNumber" :label="$t('paymentPage.accountNumber')+'*'"></v-text-field>
  <v-text-field v-model="bankInfo.bankCode" :label="$t('profile.bankCode')+'*'"></v-text-field>
  <v-text-field v-model="bankInfo.branchCode" :label="$t('paymentPage.branchCode')+'*'"></v-text-field>
  <v-text-field v-model="bankInfo.bankName" :label="$t('profile.bankName')"></v-text-field>

</div>
</template>
<script>
import NationalityDropdown from '../shared/NationalityDropdown.vue'
import moment from 'moment'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: "",
  props: ['user'],
  data: () => ({
    adminEdit:false,
    birthdayMenu: false,
    tUser:{},
    bankInfo:{},
  }),
  computed:{
    ...mapGetters({
      loggedUser: types.GET_USER
    }),
    maxBday:function(){
      return moment().subtract(18,"y").format("YYYY-MM-DD")
    },

    policies(){
      let t=[
        {text:'Flexible', value:1},
        {text:'Moderate',value:2},
        {text:'Strict',value:3},
        {text:'Non refundable',value:4}
      ]
      if (this!=undefined){
        t=[
          {text:this.$t('profile.flexible'), value:1},
          {text:this.$t('profile.moderate'),value:2},
          {text:this.$t('profile.strict'),value:3},
          {text:this.$t('profile.noRefund'),value:4}
        ]
      }
      return t
    },
    communicationOptions(){
      let t=['Email', 'Phone', 'Line', 'WeChat', 'WhatsApp', 'Facebook']
      if (this!=undefined){
        t=[
          {
            text:this.$t('tenants.email'),
            value:'Email',
          },
          {
            text:this.$t('profile.lineApp'),
            value:'Line',
          },{
            text:this.$t('profile.phone'),
            value:'Phone',
          },{
            text:this.$t('profile.wechatApp'),
            value:'WeChat',
          },{
            text:this.$t('profile.whatsapp'),
            value:'WhatsApp',
          },{
            text:this.$t('profile.facebookApp'),
            value:'Facebook',
          },
        ]
      }
      return t
    },
    genders:function() {
        let t=[{
            text: 'Male',
            value: "m"
          },
          {
            text: 'Female',
            value: "f"
          }
        ]
        if(this!=undefined){
          t=[{text: this.$t("profile.male"), value: "m"},
            {
              text: this.$t("profile.female"),
              value: "f"
            }
          ]
        }
        return t
    },

  },
  watch: {
    tUser: function() {
      this.$emit('returnUser', this.tUser)
    },
    bankInfo: function(){
      this.tUser.bankInfo=this.bankInfo
    }
  },
  created() {
    //do something after creating vue instance
    this.tUser = this.user
    if(this.user.bankInfo){
      this.bankInfo=this.user.bankInfo
    }
    if(this.$route.query.id!=undefined && this.loggedUser.roleValue>70){
      this.adminEdit=true
    }
  },

  components: {
    NationalityDropdown,
  }
}
</script>
<style lang="scss" scoped>
</style>
