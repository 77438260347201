<template lang="html">
  <div class="">
    <v-card :href="linkToRoom" hover target="_blank">
      <div class="searchresult-image">
        <img
           width="100%"
           v-lazy="baseUrl+photoId"
           :alt="'My Room Abroad - Shared rooms and studios for international students, exchange students, language teachers and expats in Aisa. '+apt.general.title+photoText"
         >
       </div>
       <v-card-title class="max-title-height subheading align-center">
         <div class="block-with-text">
            {{title}}
         </div>
       </v-card-title>
       <v-card-text>
         <v-layout >
           <v-flex xs6>


         <div class="distances">
           <div class="km" v-if="walkingDistance!='' && walkingDistance!=undefined">
             <span class="MRAIcon_distance"></span> {{walkingDistance}}
           </div>
           <div class="min" v-if="bicycleTime!='' && bicycleTime!=undefined">
             <span class="MRAIcon_bike"></span> {{bicycleTime}} {{$t("resultsPage.min")}}
           </div>
           <div class="" v-if="apt.type=='wholeApt'">
             {{apt.featureList.numberOfBedrooms}} room<span v-if="apt.featureList.numberOfBedrooms!='1'">s</span>

           </div>

         </div>
       </v-flex>
       <v-flex xs6>
         <div class="price price-twd" v-if="apt.type=='wholeApt'">
           NT$ {{apt.billing.price}}
         </div>
         <div class="price caption price-eur" v-if="currency!='TWD' && this.fxPrice>0">
           ({{currency}} {{fxPrice}})
         </div>
         <div class="" v-if="apt.type!='wholeApt'">
           {{apt.featureList.numberOfBedrooms}} rooms

         </div>
         <div class="next-availability">
           <p><span class="MRAIcon_date" size=".875rem"></span> {{nextAv}}</p>
         </div>
       </v-flex>

       </v-layout>

       </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import config from '../../config'
import '@gouch/to-title-case'

export default {
  props:  ['apt', 'mid', 'mod','lat','lng','currency','fx'],
  data:()=>({
    distanceMatrixToCenter:{},
    bicycleTime: "",
    walkingDistance:"",
  }),
  computed:{
    nextAv(){
      if (this.mid!=undefined && moment(this.mid).isBetween(moment().month("July").date('31'),moment().month("November").date('1')) && moment(this.apt.general.nextAvailability).isBefore(moment().month('July').date('31')) ){
        // console.log('this room is edited NextAv');
        return moment(this.mid).date(1).format("MMM Do YYYY")
      } else if (this.mid!=undefined && moment(this.mid).isBetween(moment().month("May").date('31'),moment().month("August").date('1')) && moment(this.apt.general.nextAvailability).isAfter(moment().month('July').date('31')) && this.summerAv==true){
        return moment(this.mid).date(1).format("MMM Do YYYY")
      } else if (moment(this.apt.general.nextAvailability).isBefore(moment())){
        return this.$t('resultsPage.now')
      } else {
        return moment(this.apt.general.nextAvailability).format("MMM Do YYYY")
      }
    },
    summerAv(){
      if (this.apt.summerBooked!=true && this.apt.septBooked==true){
        return true
      } else {
        return false
      }
    },

    baseUrl(){
      return config.SEARCHCARDURL
    },
    photoId(){
      if (this.apt.hasOwnProperty('visual') && this.apt.visual.photos!=undefined && this.apt.visual.photos[0]!=undefined){
        return this.apt.visual.photos[0].id
      } else {
        return "Logo/mra-no-photos-placeholder.jpg"
      }
    },

    fxPrice(){
      if (this.currency!='TWD' && this.fx!=undefined){
        return Math.ceil(this.fx*this.apt.billing.price)
      }
    },
    photoText(){
      if (this.apt.visual.photos!=undefined && this.apt.visual.photos[0]!=undefined){
        return this.apt.visual.photos[0].text
      } else {
        return ""
      }
    },
    linkToRoom(){
      let tempLink=""
      // console.log('setLink')
      if(this.apt!=undefined){
        if (this.apt.type=='wholeApt'){
          tempLink = "/apartmentDetails/"+ this.apt['.key']+"/apartment"
        } else {
          tempLink = "/apartmentDetails/"+ this.apt['.key']+"/rooms"
        }
        if (this.mid!="" && this.mid){
          tempLink = tempLink +"?mid="+this.mid
        }
        if (this.mod!="" && this.mod){
          // console.log('isNaN check')
          // console.log(this.dates.moveOutDate)
          if(tempLink.includes('?')){
            tempLink=tempLink+"&mod="+this.mod
          } else{
            tempLink=tempLink+"?mod="+this.mod
          }
        }
        return tempLink
      }
    },
    title(){
      if (this.apt.general.title!=undefined){
        return this.apt.general.title.toLowerCase().toTitleCase()
      } else {
        return 'Untitled Apartment'
      }
    }
  },
  methods: {

    setMatrix() {
      // console.log('do distanceMatrixToCent');
      if (this.apt.hasOwnProperty('general') && this.apt.general.location!=undefined && this.lat!=undefined){

        // console.log('doing distance matrix: ', this.lat, this.lng, this.apt.general.location);
        let meters=this.getDistanceFromLatLonInKm(this.lat, this.lng, this.apt.general.location.lat, this.apt.general.location.lng)*1.2*1000
        let time=meters/1000/4.7
        this.distanceMatrixToCenter={
          meters:meters,
          time:time
        }
        // this.walkingDistance=this.distanceMatrixToCenter.meters
        this.bicycleTime = Math.ceil(this.distanceMatrixToCenter.meters / 1000 / 9.8 * 60)
        if(this.distanceMatrixToCenter.meters>1000){
          this.walkingDistance= Math.round(this.distanceMatrixToCenter.meters/100)/10 + ' km'
        } else {
          this.walkingDistance= Math.round(this.distanceMatrixToCenter.meters) + " m"
        }

        this.$emit('setDistance',this.distanceMatrixToCenter.time)

      }

    },
    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1);
      var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c; // Distance in km
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI/180)
    },
  },
  watch:{
    lat(){
      console.log('lat changed');
      this.setMatrix()
    },
    lng(){
      console.log('lng changed');
      this.setMatrix()
    }
  },
  created(){
    this.setMatrix()
  },
  filters:{

    date(t){
      return moment(t).format("LL")
    }
  }
}
</script>

<style lang="scss" scoped>
.max-title-height{
  height:4em;
}
.block-with-text {
  display: -webkit-box;
  max-width: 100%;
  height: 2.4em; /* Fallback for non-webkit */
  line-height: 1.2em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchresult-image{
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding: 56.4285714% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */


   img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.price.price-twd {
    font-size: 1.25rem;
    color: #00bFFF;
}
.next-availability {
    color: rgba(0,0,0,.875);
}

</style>
