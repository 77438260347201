<template>
<div id="">
  <v-layout  wrap>
    <v-flex xs12 class='pa-2'>
      <h2 class="headline">{{$t("profile.basicProfile")}}</h2>
    </v-flex>
    <v-flex xs12 >

      <div data-vl-widget="sharingWidget"></div>
    </v-flex>

    <v-flex xs12 class='pa-2'>
      {{$t("profile.username")}}: <span class="result">{{user.userName}}</span>
    </v-flex>
    <v-flex xs6 class='pa-2'>
      {{$t("tenants.email")}}: <span class="result">{{user.email}}</span>
    </v-flex>
    <v-flex xs6 class="text-xs-right pa-2">
      <span class="pointer"  @click="changeEmailDisplay=!changeEmailDisplay">{{$t("profile.changeEmail")}}</span>
    </v-flex>
    <v-flex xs12 v-if="changeEmailDisplay">
      <v-text-field
        :error-messages="newEmailErrorMessage"
        v-model="newEmail"
        :label="$t('profile.newEmail')"
        type="email"
        @input="$v.newEmail.$touch()"

      >
      </v-text-field>
      <v-btn color="primary" flat :disabled="sending" @click="changeEmailDialog=true">{{$t("profile.basicProfile")}}</v-btn>
    </v-flex>

    <v-flex xs6 class='pa-2'>
    {{$t("profile.password")}}: <span class="result">******</span>
    </v-flex>
    <v-flex xs6 class="text-xs-right pa-2">
      <span class="pointer"  @click="changePasswordDisplay=!changePasswordDisplay">{{$t("profile.changePassword")}}</span>
    </v-flex>
    <v-flex xs12 v-if="changePasswordDisplay">
      <v-text-field
        :error-messages="newPasswordErrorMessage"
        v-model="newPassword"
        label="New Password"
        type="password"
        @input="$v.newPassword.$touch()"

      >
      </v-text-field>
      <v-btn color="primary" flat :disabled="sending" @click="changePassword">{{$t("profile.save")}}</v-btn>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.currency")}}: <span class="result">{{user.currency}}</span>
    </v-flex>
  </v-layout>
  <v-layout  wrap>
    <v-flex xs12 class="headline pa-2">
      {{$t("profile.officialInfo")}}
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.name")}}: <span class="result">{{user.name}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.phone")}}: <span class="result">{{user.phone}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.birthday")}}: <span class="result">{{birthday}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.gender")}}: <span class="result">{{gender}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("tenants.nationality")}}: <span class="result">{{nationality}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.passport")}}: <span class="result">{{user.passport}}</span>
    </v-flex>

  </v-layout>
  <v-layout  wrap>
    <v-flex xs12 class="headline pa-2">
      {{$t("profile.personalDescription")}}
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.occupation")}}: <span class="result">{{occupation}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.school")}}: <span class="result">{{user.school}}</span>
    </v-flex>
    <v-flex xs12 class="pa-2">
      {{$t("profile.aboutYou")}}: <span class="result">{{user.about}}</span>
    </v-flex>
    <v-flex xs12>
      <div class="" >
        <div data-vl-widget="rewardStats"></div>
      </div>

    </v-flex>
  </v-layout>



  <v-layout  justify-center>
    <v-dialog v-model="changeEmailDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">{{$t("profile.changeEmail")}}</v-card-title>
        <v-card-text>{{$t("profile.changeEmailText1",{newEmail:newEmail})}} <br /> {{$t("profile.changeEmailText2")}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="changeEmailDialog = false">{{$t("datePicker.cancel")}}</v-btn>
          <v-btn color="primary" flat @click.native=" changeEmail">{{$t("requestsInbox.confirm")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import axiosAuth from '../../axios-auth'
import config from '../../config'
import {
  mapActions
} from 'vuex'
import {  email, minLength} from 'vuelidate/lib/validators'
import countries from "i18n-iso-countries"

import * as types from '../../store/types'
export default {
  name: "",
  props: ['user'],
  data: () => ({
    sending: false,
    birthday: "",
    newEmail: "",
    newPassword: "",
    changePasswordDisplay: false,
    changeEmailDisplay: false,
    changeEmailDialog: false,
  }),
  methods: {
    ...mapActions({
      refreshUser: types.REFRESH_TOKEN
    }),

    changePassword() {
      this.changeEmailDialog = false
      this.sending = true
      const toChangePassword = {
        idToken: this.$store.state.auth.idToken,
        password: this.newPassword,
        returnSecureToken: true
      }
      console.log('Change to:', toChangePassword)
      axiosAuth.post('setAccountInfo?key=' + config.FIREBASE_API_KEY, toChangePassword)
      .then(res => {
        // console.log('response:', res.data);
        this.$router.push('/login')
        this.sending = false
      })
      .catch(err=>{
        console.log('error: ',err);
      })

    },

    changeEmail() {
      this.changeEmailDialog = false
      this.sending = true
      const toChangeEmail = {
        idToken: this.$store.state.auth.idToken,
        email: this.newEmail,
        returnSecureToken: false
      }
      axiosAuth.post('setAccountInfo?key=' + config.FIREBASE_API_KEY, toChangeEmail)
        .then(res => {
          let toSend = {
            email: res.data.email
          }
          axios.patch('users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, toSend)
            .then(() => {
              this.refreshUser()
              this.sending = false
            })
            .catch(err=>{
              console.log('error: ',err);
            })

        })
        .catch((err)=>{
          this.newEmailErrorMessage.push(this.$t('profile.emailError'))
          console.log(err, 'error');
        })
    }

  },
  created() {
    //do something after creating vue instance
    if (this.user.birthday) {
      this.birthday = moment(this.user.birthday).format('DD MMM YYYY')
    }

  },
  computed:{
    occupation(){
      if (this.user.occuptaion!=undefined){
        switch(this.user.occupation.toLowerCase()){
          case 'student':
          return this.$t("otherTenants.student")
          case 'worker':
          return this.$t("otherTenants.worker")
          default:
          return ""
        }
      } else {
        return ""
      }
    },
    gender(){
      switch (this.user.gender) {
        case "m":
          return this.$t("profile.male")
        case "f":
          return this.$t("profile.female")
        default:
          return ""
      }
    },
    nationality(){
      countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"));
      return countries.getName(this.user.nationality, this.$i18n.locale)
    },
    newEmailErrorMessage(){
      let errors=[]
      if (!this.$v.newEmail.$dirty) return errors
      !this.$v.newEmail.email && errors.push(this.$t("profile.emailError"))
      return errors
    },
    newPasswordErrorMessage(){
      let errors=[]
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.minLength && errors.push(this.$t("profile.passwordError"))
      return errors

    }
  },
  validations:{
    newEmail:{
      email
    },
    newPassword:{
      minLength:minLength(6)
    }
  }
}
</script>
<style lang="scss" scoped>
.pointer {
    cursor: pointer;
    color: #00BFFF;
    float: right;
}
.list-element {
    margin-top: 1rem;
    color: rgba(0,0,0,.87);
}
.result {
    margin-top: 0;
    display: block;
    color: rgba(0,0,0,.6);
}
.md-title{
  margin-top:1em;
}

</style>
