<template>
<div id="">
  <v-text-field
    v-model="tUser.userName"
    :label="$t('profile.username')"
    >
  </v-text-field>
  <v-select
    :items="currencyOptions"
    v-model="tUser.currency"
    :label="$t('profile.currency')"
  ></v-select>
  <v-text-field v-model="tUser.name" :label="$t('profile.name')" :hint="$t('profile.nameHint')"></v-text-field>
  <v-text-field v-model="user.phone" hint="Format: +32XXXXXXXXX" :error-messages="phoneError" :label="'Phone'" @change="$v.user.phone.$touch()" ></v-text-field>
  <v-menu ref="birthdayMenu" :close-on-content-click="false" v-model="birthdayMenu" offset-y full-width :nudge-right="40" min-width="290px">
    <v-text-field slot="activator" :label="$t('profile.birthday')" v-model="tUser.birthday" prepend-icon="event" readonly></v-text-field>
    <v-date-picker :locale="$vuetify.lang.current" ref="picker" v-model="tUser.birthday" @change="$refs.birthdayMenu.save()" min="1950-01-01" :max="maxBday"></v-date-picker>
  </v-menu>
  <v-select :items="genders" v-model="tUser.gender" :label="$t('profile.gender')"></v-select>
  <NationalityDropdown :nationality="tUser.nationality" @changeNationality="tUser.nationality=$event"></NationalityDropdown>
  <v-text-field v-model="tUser.passport" :label="$t('profile.passport')"></v-text-field>
  <v-select :items="occupationOptions" v-model="tUser.occupation" :label="$t('profile.occupation')"></v-select>
  <v-text-field v-model="tUser.school" :label="$t('profile.school')" :hint="$t('profile.schoolHint')"></v-text-field>
  <v-textarea v-model="tUser.about" :label="$t('profile.about')" :hint="$t('profile.aboutHint')">
  </v-textarea>

</div>
</template>
<script>
import NationalityDropdown from '../shared/NationalityDropdown.vue'
import moment from 'moment'
export default {
  name: "",
  props: ['user'],
  data: () => ({
    tUser:{},
    birthdayMenu: false,
    currencyOptions: [{
        text: "New Taiwan Dollar (TWD)",
        value: "TWD"
      },
      {
        text: "Euro (EUR)",
        value: "EUR"
      },
      {
        text: "US Dollar (from any USD denominated bank accounts) (USD)",
        value: "USD"
      },
      {
        text: "GB Pound (from any bank account in the UK) (GBP)",
        value: 'GBP'
      },
      {
        text: "Australian Dollar (from any bank account in Australia) (AUD)",
        value: "AUD"
      },
      {
        text: "Chinese Yuan (CNY)",
        value: "CNY",
      },
      {
        text: "Honk Kong Dollar (HKD)",
        value: "HKD"
      },
      {
        text: "Japanese Yen (JPY)",
        value: "JPY",
      },
      {
        text: "Canadian Dollar (CAD)",
        value: "CAD",
      },
      {
        text: "South African Rand (ZAR)",
        value: "ZAR",
      },
      {
        text: "New Zealand Dollar (NZD)",
        value: "NZD"
      },
      {
        text: "Swiss Franc (CHF)",
        value: "CHF"
      },
      {
        text: "Swedish Kron (SEK)",
        value: "SEK"
      },
      {
        text: "Singapore Dollar (SGD)",
        value: "SGD",
      },
      {
        text: "Mexican Peso (MXN)",
        value: "MXN",
      },
      {
        text: "Thai Baht (THB)",
        value: "THB"
      }
    ],
  }),
  computed:{
    maxBday:function(){
      return moment().subtract(18,"y").format("YYYY-MM-DD")
    },

    genders:function() {
        let t=[{
            text: 'Male',
            value: "m"
          },
          {
            text: 'Female',
            value: "f"
          }
        ]
        if(this!=undefined){
          t=[{
              text: this.$t("profile.male"),
              value: "m"
            },
            {
              text: this.$t("profile.female"),
              value: "f"
            }
          ]
        }
        return t
    },
    occupationOptions:function(){
      let t=[{
        text: 'Student',
        value: "student"
      }, {
        text: 'Worker',
        value: 'worker'
      }]
      if (this!=undefined){
        t=[
          {
            text: this.$t('otherTenants.student'),
            value: "student"
          }, {
            text: this.$t('otherTenants.worker'),
            value: 'worker'
          }
        ]
      }
      return t

    }
  },
  watch: {
    tUser: function() {
      this.$emit('returnUser', this.tUser)
    }
  },
  created(){
    this.tUser=this.user
  },
  components: {
    NationalityDropdown,
  }
}
</script>
<style lang="scss" scoped>
</style>
