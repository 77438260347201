import { render, staticRenderFns } from "./LandlordEdit.vue?vue&type=template&id=16d408fe&scoped=true&"
import script from "./LandlordEdit.vue?vue&type=script&lang=js&"
export * from "./LandlordEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d408fe",
  null
  
)

export default component.exports