<template>
<div id="">

    <v-layout  wrap>
      <v-flex xs12 class='pa-2'>
        <h2 class="headline">{{$t("profile.basicProfile")}}</h2>
      </v-flex>
      <v-flex xs12 class='pa-2'>
        {{$t("profile.username")}}: <span class="result">{{user.userName}}</span>
      </v-flex>
      <v-flex xs12 class='pa-2' v-if="this.loggedUser.roleValue>70">
        Nickname: <span class="result">{{user.nickname}}</span>
      </v-flex>

      <v-flex xs6 class='pa-2'>
        {{$t("tenants.email")}}: <span class="result">{{user.email}}</span>
      </v-flex>
      <v-flex xs6 class="text-xs-right pa-2">
        <span class="pointer"  @click="changeEmailDisplay=!changeEmailDisplay">{{$t("profile.changeEmail")}}</span>
      </v-flex>
      <v-flex xs12 v-if="changeEmailDisplay">
        <v-text-field
          :error-messages="newEmailErrorMessage"
          v-model="newEmail"
          :label="$t('profile.newEmail')"
          type="email"
          @input="$v.newEmail.$touch()"
        >
        </v-text-field>
        <v-btn color="primary" flat :disabled="sending" @click="changeEmailDialog=true">{{$t("profile.save")}}</v-btn>
      </v-flex>

      <v-flex xs6 class='pa-2'>
        {{$t("profile.password")}}: <span class="result">******</span>
      </v-flex>
      <v-flex xs6 class="text-xs-right pa-2">
        <span class="pointer"  @click="changePasswordDisplay=!changePasswordDisplay">{{$t("profile.changePassword")}}</span>
      </v-flex>
      <v-flex xs12 v-if="changePasswordDisplay">
        <v-text-field
          :error-messages="newPasswordErrorMessage"
          v-model="newPassword"
          :label="$t('profile.newPassword')"
          type="password"
          @input="$v.newPassword.$touch()"

        >
        </v-text-field>
        <v-btn color="primary" flat :disabled="sending" @click="changePassword">{{$t("profile.save")}}</v-btn>
      </v-flex>
      <v-flex xs12 v-if="user.sendChinese==true">
        {{$t("profile.sendChinese")}}
      </v-flex>
      <v-flex xs12>
        {{$t("profile.cancelPolicy")}}: {{cancelPol}}
      </v-flex>
      <v-flex xs12 class='pa-2' v-if="user.trustVal>0">
        {{$t("profile.verified")}}
      </v-flex>
      <v-flex xs12 class='pa-2' v-else>
        <v-btn v-if="!verificationRequested" color="primary" flat @click="verifyMe">{{$t("profile.getVerified")}}</v-btn>
        <span v-if="verificationRequested">{{$t("profile.verificationRequested")}}</span>
      </v-flex>
    </v-layout>
    <v-layout  wrap>
      <v-flex xs12 class="headline pa-2">
        {{$t("profile.officialInfo")}}
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.name")}}: <span class="result">{{user.name}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.address")}}: <span class="result">{{user.address}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.birthday")}}: <span class="result">{{birthday}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.gender")}}: <span class="result">{{gender}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("tenants.nationality")}}: <span class="result">{{nationality}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.passport")}}: <span class="result">{{user.passport}}</span>
      </v-flex>

    </v-layout>
    <v-layout  wrap>
      <v-flex xs12 class="headline pa-2">
        {{$t("profile.communicationInfo")}}
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.phone")}}: <span class="result">{{user.phone}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.line")}}: <span class="result">{{user.lineId}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.wechat")}}: <span class="result">{{user.weChat}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.facebook")}}: <span class="result">{{user.facebook}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.preferredMethod")}}: <span class="result">{{communicationPref}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2" v-if="user.companyInv==true">
        {{$t("profile.companyInv")}}
      </v-flex>

      <v-flex xs12 class="pa-2">
        {{$t("profile.companyId")}}:<span class="result">{{user.companyId}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.companyName")}}:<span class="result">{{user.companyName}}</span>
      </v-flex>
    </v-layout>

    <v-layout  wrap v-if="user.bankInfo!=undefined">
      <v-flex xs12 class="headline pa-2">
        {{$t("profile.bankInfo")}}
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("paymentPage.accountHolder")}}:<span class="result">{{user.bankInfo.holderName}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("paymentPage.accountNumber")}}: <span class="result">{{user.bankInfo.accountNumber}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.swift")}}: <span class="result">{{user.bankInfo.swift}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.bankName")}}: <span class="result">{{user.bankInfo.bankName}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.bankCode")}}: <span class="result">{{user.bankInfo.bankCode}}</span>
      </v-flex>
      <v-flex xs12 class="pa-2">
        {{$t("profile.bankAddress")}}: <span class="result">{{user.bankInfo.bankAddress}}</span>
      </v-flex>
    </v-layout>
    <v-layout  justify-center>
      <v-dialog v-model="changeEmailDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{$t("profile.changeEmail")}}</v-card-title>
          <v-card-text>{{$t("profile.changeEmailText1",{newEmail:newEmail})}} <br /> {{$t("profile.changeEmailText2")}}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click.native="changeEmailDialog = false">{{$t("datePicker.cancel")}}</v-btn>
            <v-btn color="primary" flat @click.native=" changeEmail">{{$t("requestsInbox.confirm")}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
</div>
</template>
<script>
import countries from "i18n-iso-countries"
import moment from 'moment'
import axios from 'axios'
import axiosAuth from '../../axios-auth'
import config from '../../config'
import AWSSES from '../../aws-ses'
import {
  mapActions,
  mapGetters,
} from 'vuex'
import * as types from '../../store/types'
import { email, minLength} from 'vuelidate/lib/validators'

export default {
  name: "",
  props: ['user'],
  data: () => ({
    bankInfo: {},
    sending: false,
    verificationRequested:false,
    birthday: "",
    newEmail: "",
    newPassword: "",
    changePasswordDisplay: false,
    changeEmailDisplay: false,
    changeEmailDialog: false,
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'Admin'
    },

  }),
  methods: {
    ...mapActions({
      refreshUser: types.REFRESH_TOKEN
    }),

    changePassword() {
      this.changeEmailDialog = false
      this.sending = true
      const toChangePassword = {
        idToken: this.$store.state.auth.idToken,
        password: this.newPassword,
        returnSecureToken: false
      }
      console.log('Change to:', toChangePassword)
      axiosAuth.post('setAccountInfo?key=' + config.FIREBASE_API_KEY, toChangePassword)
        .then(() => {
          console.log('response:', res.data);
          this.$router.push('/login')
          this.sending = false
        })
        .catch(err=>{
          console.log('error: ',err);
        })

    },
    verifyMe(){
      this.verificationRequested=true
      axios.patch('users/'+ this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {verifyMe:true})
      .then(()=>{
        let info={
          landlord:this.user.userName
        }
        AWSSES.emailingInfo(this.admin, info,'AGetVerified')
        if (this.user.sendChinese==true){

          AWSSES.emailingInfo(this.user, info,'ZHLLGetVerified')
        } else {
          AWSSES.emailingInfo(this.user, info,'LLGetVerified')
        }
      })
    },
    changeEmail() {
      this.changeEmailDialog = false
      this.sending = true
      const toChangeEmail = {
        idToken: this.$store.state.auth.idToken,
        email: this.newEmail,
        returnSecureToken: false
      }
      axiosAuth.post('setAccountInfo?key=' + config.FIREBASE_API_KEY, toChangeEmail)
        .then(res => {
          let toSend = {
            email: res.data.email
          }
          axios.patch('users/' + this.user.id + '.json' + '?auth=' + this.$store.state.auth.idToken, toSend)
            .then(() => {
              this.refreshUser()
              this.sending = false
            })
            .catch(err=>{
              console.log('error: ',err);
            })

        })
        .catch(err=>{
          console.log('error: ',err);
        })

    }

  },
  created() {
    //do something after creating vue instance
    if (this.user.birthday) {
      this.birthday = moment(this.user.birthday).format('DD MMM YYYY')
    }
    if (this.user.bankInfo!=undefined) {
      this.bankInfo = this.user.bankInfo
    }
    if (this.user.verifyMe){
      this.verificationRequested=true
    }

  },
  computed:{
    ...mapGetters({
      loggedUser: types.GET_USER
    }),

    communicationPref(){
      switch(this.user.communication){
        case 'Email':
          return this.$t("tenants.email")
        case 'Line':
          return this.$t("profile.lineApp")

        case 'Phone':
          return this.$t("profile.phone")

        case 'WeChat':
          return this.$t("profile.wechatApp")
        case 'Facebook':
          return this.$t("profile.facebookApp")
        case 'WhatsApp':
          return this.$t("profile.whatsapp")
        default:
          return ""
      }
    },
    gender(){
      switch (this.user.gender) {
        case "m":
          return this.$t("profile.male")
        case "f":
          return this.$t("profile.female")
        default:
          return ""
      }
    },
    nationality(){
      countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"));
      return countries.getName(this.user.nationality, this.$i18n.locale)
    },
    cancelPol(){
      switch (this.user.cancelPol) {
        case 1:
          return this.$t("profile.flexible")
        case 2:
          return this.$t("profile.moderate")
        case 3:
          return this.$t("profile.strict")
        case 4:
          return this.$t("profile.noRefund")
        default:
          return this.$t("profile.moderate")
      }
    },
    newEmailErrorMessage(){
      let errors=[]
      if (!this.$v.newEmail.$dirty) return errors
      !this.$v.newEmail.email && errors.push(this.$t('profile.emailError'))
      return errors
    },
    newPasswordErrorMessage(){
      let errors=[]
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.minLength && errors.push(this.$t("profile.emailError"))
      return errors

    },

  },
  validations:{
    newEmail:{
      email
    },
    newPassword:{
      minLength:minLength(6)
    }
  }


}
</script>
<style lang="scss" scoped>
.pointer {
    cursor: pointer;
    color: #00BFFF;
    float: right;
}
.list-element {
    margin-top: 1rem;
    color: rgba(0,0,0,.87);
}
.result {
    margin-top: 0;
    display: block;
    color: rgba(0,0,0,.6);
}
.md-title {
    margin-top: 1em;
}
.verified {
    margin-top: 4px;
}
</style>
