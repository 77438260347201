<template>

<div id="">
  <Navbar ></Navbar>
  <v-layout wrap justify-space-around>
    <v-flex pa-4 xs12 class="text-xs-center">
      <h1>Your Saved Listings <v-icon color="red" large>favorite</v-icon></h1>
    </v-flex>
    <v-flex xs10>
      <v-layout wrap>

        <v-flex xs12 sm6 md4 class="pa-2" v-for="room in rooms" :key="room['.key']">
          <RoomCard  :room="room" currency="TWD"></RoomCard>
        </v-flex>
        <v-flex xs12 sm6 md4 class="pa-2" v-for="apt in apartments" :key="apt['.key']">
          <AptCard  :apt="apt" currency="TWD"></AptCard>

        </v-flex>
      </v-layout>
    </v-flex>

  </v-layout>
</div>
</template>
<script>
import axios from 'axios'
import firebase from '../../FirebaseApp'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import RoomCard from '../results2/RoomCard.vue'
import AptCard from '../results2/AptCard.vue'

export default {
  name: "",
  data: () => ({
    rooms:[],
    apartments:[],
  }),
  firebase() {
    return {
    }
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),

  },
  methods: {
    getListing(url){
      if (url.includes('apartmentDetails') && url.includes('rooms')){
        let id=url.split('apartmentDetails/')[1]
        id=id.split('/')[0]
        console.log(id);
        axios.get('apartments/'+id+'.json')
        .then(res=>{
          console.log(res.data);
          let list=res.data
          list['.key']=id
          list.type="roomApt"
          this.apartments.push(list)
        })
      } else if (url.includes('apartmentDetails')) {
        let id=url.split('apartmentDetails/')[1]
        id=id.split('/')[0]
        console.log(id);
        axios.get('wholeApts/'+id+'.json')
        .then(res=>{
          console.log(res.data);
          let list=res.data
          list['.key']=id
          list.type="wholeApt"
          this.apartments.push(list)
        })

      } else if (url.includes('studioDetails')){
        let id=url.split('studioDetails/')[1]
        id=id.split('/')[0]
        console.log(id);
        axios.get('studios/'+id+'.json')
        .then(res=>{
          console.log(res.data);
          let room=res.data
          room['.key']=id
          room.type="studio"
          this.rooms.push(room)
        })
      } else if (url.includes('details')){
        let id=url.split('details/')[1]
        id=id.split('/')[0]
        console.log(id);
        axios.get('rooms/'+id+'.json')
        .then(res=>{
          console.log(res.data);
          let room=res.data
          room['.key']=id
          room.type="room"
          this.rooms.push(room)
        })
      }
    }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    // console.log(this.user);
    for (var el in this.user.favs) {
      if (this.user.favs.hasOwnProperty(el)) {
        let t=this.user.favs[el]
        if (t.url!=undefined){
          console.log(t.url);
          this.getListing(t.url)
        }
      }
    }
  },

  components: {
    AptCard,
    RoomCard
  },

}
</script>

<style lang="scss" scoped>

</style>
