<template>
<div class="">

  <navbar></navbar>
  <v-container >
    <v-layout justify-center  wrap>
      <v-flex xs11 sm9 md7 lg6>
        <v-layout  wrap>

        <v-flex xs12>
          <h1 class="display-1">{{$t("profile.editProfile")}}</h1>
        </v-flex>
      </v-layout>

    <UserEdit v-if="tUser.roleValue<=10" :user="tUser" @returnUser="tUser=$event"></UserEdit>
    <LandlordEdit v-if="tUser.roleValue>10" :user="tUser" @returnUser="tUser=$event"></LandlordEdit>
    <!-- <UserEdit v-if="tUser.roleValue>20" :user="tUser" @returnUser="tUser=$event"></UserEdit> -->
    <v-btn color="primary" :disabled="uploading" @click="saveUser">{{$t("profile.save")}}</v-btn>
  </v-flex>
</v-layout>
</v-container>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import * as types from '../../store/types'
import UserEdit from './UserEdit.vue'
import LandlordEdit from './LandlordEdit.vue'
import AdminEdit from './AdminEdit.vue'
import axios from 'axios'



export default {
  name: "",
  data: () => ({
    uploading:false,
    tUser:{}
  }),
  methods: {
    saveUser() {
      this.uploading=true
      let userNameRegex = /^[\x20-\x7E]*$/;
      if (userNameRegex.test(this.tUser.userName)){
        axios.patch('users/'+this.tUser.id+'.json'+'?auth=' + this.$store.state.auth.idToken,this.tUser)
        .then(()=>{
          this.$router.push({name:'homepage'})
        })
        .catch(err=>{
          console.log('error: ',err);
        })
      } else {
        alert(this.$t('profile.username')+': ' + this.$t('auth.userNameRegexErr'))
        this.uploading=false
      }

   }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created(){
    // console.log(this.$route, 'router')
    if (this.$route.query.id!="" && this.$route.query.id!= undefined &&this.user.roleValue>20){
      axios.get('users/'+this.$route.query.id+'.json')
      .then(res=>{
        this.tUser=res.data
        this.tUser.id=this.$route.query.id
      })
      .catch(err=>{
        console.log(err,', userError');
      })
    } else {
      this.tUser=this.user
    }

  },
  components: {
    UserEdit,
    LandlordEdit,
    AdminEdit
  }
}
</script>
<style lang="scss" scoped>
</style>
