<template>
<div id="">
  <navbar></navbar>
  <v-container >
    <v-layout justify-center  wrap>
      <v-flex xs11 sm9 md7 lg6>
        <v-layout  wrap>
          <v-flex xs12>
            <h1 class="display-1">{{$t("profile.yourProfile")}}</h1>
          </v-flex>
        </v-layout>
        <User v-if="tUser.roleValue<=10" :user="tUser"></User>
        <Landlord v-if="tUser.roleValue>10" :user="tUser"></Landlord>
        <!-- <User v-if="tUser.roleValue>20" :user="tUser"></User> -->
        <v-btn color="error" flat @click="deleteAccount">{{$t("profile.deleteAccount")}}</v-btn>
        <v-btn color="primary" flat @click="$router.push({name:'profileEdit', query:$route.query})">{{$t("profile.editInfo")}}</v-btn>
      </v-flex>
    </v-layout>
  </v-container>

</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import * as types from '../../store/types'
import User from './User.vue'
import Landlord from './Landlord.vue'
import Admin from './Admin.vue'
import axios from 'axios'

export default {
  name: "",
  data: () => ({
    tUser:{},
  }),
  methods: {
    deleteAccount() {
      alert('Please send an email to hello@myroomabroad.com to completely remove your account. In meanwhile it has been archived and noone except you may access it.')
    }
  },
  created(){
    // console.log(this.$route, 'router')
    if (this.$route.query.id!="" && this.$route.query.id!= undefined &&this.user.roleValue>20){
      axios.get('users/'+this.$route.query.id+'.json')
      .then(res=>{
        this.tUser=res.data
      })
      .catch(err=>{
        console.log(err,', userError');
      })
    } else {
      this.tUser=this.user
    }

  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    })
  },
  components: {
    User,
    Landlord,
    Admin
  }
}
</script>
<style lang="scss" scoped>
.md-small{
  color:rgba(0,0,0,.38)
}
.actions{
  float:right
}
.profile-title {
    margin-bottom: 0;
    margin-top: 1em;
}
</style>
